.alert {
    display: flex;
    align-items: center;
    justify-content: center;
    &.alert--info {
        border: solid 3px $color-fourth;
        color: $color-fourth;
    }
    &.alert--danger {
        border: solid 3px $color-primary;
        color: $color-primary;
    }
    &.alert--success {
        border: solid 3px $color-success;
        color: $color-success;
    }
}