.content-box-infos {
    border: solid 10px $color-secondary;
    padding: 20px;
    width: 100%;
    height: 100%;
    color: $color-secondary;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    text-align: center;
    .title {
        font-size: 3rem;
    }
    .sub-title {
        font-size: 2rem;
    }
    .tel {
        font-size: 3rem;
    }
    p {
        font-weight: bold;
    }
    img {
        margin-bottom: 20px;
    }
    .filet {
        width: 60px;
        padding-top:3px;
        background-color: $color-tertiary;
        margin-bottom: 20px;
    }
   
    @media (min-width:$large) { 
        margin-right: 50px;
        min-width: 300px;
        height: 300px;
        max-width: 200px;
        &:last-child {
            margin-right: 0;
        }
    }
}