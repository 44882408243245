@media (max-width:$large) {
    nav[role="navigation"] {
        position: relative;

        .container-menu.open ~ .content-menu-mobile {
            transform: translateY(calc(0% - 1.5rem));
            transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
        }

        .content-menu-mobile {
            z-index: 0;
            align-items: start;
            width: 100%;
            position: fixed;
            padding: 0;
            left: 0;
            top: 8.8rem;
            height: 100vh;
            transform: translateY(calc(-100% - 3rem));
            transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
            background-color: $color-white;

            li {
                list-style-type: none;
                line-height: 3;
                a {
                    text-decoration: none;
                }
            }
        }
    }
}
@media (min-width:$large) {
    .content-menu-mobile {
        display: none;
    }
}