html {
    font-size: 65.5%;
    font-size: calc(1em * 0.625);
}

body {
    margin: 0;
    @include font-size(base);
    background-color: $white;
    color: $color-tertiary;
    line-height: $line-height-base;
    display: flex;
    //height: 100vh;
    flex-direction: column;
    justify-content: space-between;
}

a {
    color: $link-color;
    text-decoration: $link-decoration;

    &:hover,
    &:active {
        color: $link-color-hover;
        text-decoration: $link-decoration-hover;
    }

    &:focus {
        outline-color: $color-tertiary;
        outline-style: auto;
        outline-width: 4px;
    }
}

p {
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 1.4;

    a {
        font-weight: $weight-medium;
    }
}

strong {
    font-weight: bold;
}

h1 {
    @include font-size(h1);

    font-weight: $weight-bold;
    color: $color-primary
}

h2 {
    @include font-size(h2);

    font-weight: $weight-bold;
    color: $color-primary;
    line-height: 1.4;
    margin-bottom: 2rem;
}

h3 {
    @include font-size(h3);

    font-weight: $weight-bold;
    color: $color-fourth;
    line-height: 1;
    margin-bottom: 1.4rem;
}
ul {
    list-style-type: none;
}