@mixin button {
    padding: 0.5rem 2rem;
    font-size: 1.6rem;
    border: none;
}
.btn--return {
    text-decoration: underline;
    font-weight: bold;
    color: $color-primary;
    font-size: 1.6rem
}

.btn--primary {
    @include button;

    background-color: $color-primary;
    color : $color-white;
    
    &:hover, &:active, &:focus, &:disabled {
        background-color: $link-color-hover;
        color : $color-white;
    }
}

.btn--secondary {
    @include button;

    background-color: $color-white;
    border: solid 1px $color-secondary;
    
    &:hover, &:active, &:focus, &:disabled {
        background-color: $color-secondary;
        color: $color-white;
    }
}

.btn--remove {
    @include button;
    background-color: $color-tertiary;
    color: $color-white;
    
    &:hover, &:active, &:focus, &:disabled {
        color: $color-primary;
    }
}

.btn--add {
    @include button;
    background-color: $color-primary;
    color: $color-white;
    &:hover, &:active, &:focus, &:disabled {
        background-color: $link-color-hover;
        color: $color-white;
    }
}

.btn--delete {
    @include button;
    color: $color-white;
    background-color: $color-fourth;
    &:hover, &:active, &:focus, &:disabled {
        background-color: $color-secondary;
        color: $color-white;
    }
}

.content-return {
    margin-bottom: 20px;
    padding-left: 0;
}

.input-group-append button{
    height: 100%;
    width: 46px;
    background-color: $color-primary;
    &:hover {
        background-color: $color-secondary;
    }
    svg {
        color: $color-white;
        font-size: 1.6rem;
    }
}