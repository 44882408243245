#registerLoginModal, #reservationExistModal {
    .modal-dialog {
        top: 20%;
        padding: 3rem;
        .modal-header {
            border-top: solid 15px $color-secondary;
            border-bottom: 0;
        }
        .modal-body {
            .col-6:first-child {
                border-right: solid 1px $color-tertiary;
            }
            .block-login, .block-content-create-account {
                padding: 2rem;
                text-align: center;
                h2 {
                    text-align: center;
                }
            }
        }
    }
    @media (min-width:$large) { 
        .modal-dialog {
            min-width: 700px;
        }
    }
}