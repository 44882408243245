header[role="banner"] {
    position: fixed;
    width: 100%;
    z-index: 900;
    .container-top-menu {
        width: 100%;
        height: 15px;
        background-color: $color-primary;
        position: relative;
        z-index: 200;
    }
}