.container-navbar {
    background-color: $color-white;
    position: relative;
    &::after {
        box-shadow: 0 0 12px $color-black;
        height: 40px;
        border-radius: 144px/11px;
        width: 100%;
        bottom: 0;
        position: absolute;
        content: "";
    }

    nav[role="navigation"] {
        position: relative;
        display: flex;
        flex-direction: row;
        padding: 0;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        min-height: 60px;
        background-color: $color-white;
        z-index: 20;

        .container-menu {
            position: relative;
            z-index: 10;
            width: calc(100% - 30px);
            display: flex;
        }

        .container-account {
            display: flex;
            align-items: center;
            position: relative;

            ul {
                padding: 0;
                list-style-type: none;
                margin-bottom: 0;
            }

            .btn--account {
                position: relative;
                border: 0;
                padding: 0;
                background: transparent;

                span {
                    display: none;
                }

                &:before {
                    content: url('../../../img/ico-account.svg');
                    display: block;
                    text-align: center;
                    position: relative;
                }
            }

            .account-dropdown-items {
                margin: 0;
                position: absolute;
                right: 0;
                top: 50%;
                justify-content: space-between;
                visibility: hidden;
                z-index: -1;
                opacity: 0;
                transition: all 0.4s cubic-bezier(0.93, 0.88, 0.1, 0.8);
                background-color: $color-white;
                border-radius: 25px;
                padding: 20px;
            }
    
            .menu-account.open ~ .account-dropdown-items {
                top: calc(100% + 25px);
                visibility: visible;
                opacity: 1;
            }
        }

        .logo {
            align-self: center;
            display: inline-flex;
            width: auto;
            height: auto;
            margin-left: calc(50% - 80px);
            position: absolute;
            @media (min-width:$large) {
                margin-left: 0;
                margin-right: 150px;
                position: inherit;
            }
        
            img {
                width: 90px;
            }
        }
    }

    
[class^="content-menu-"] li a {
    position: relative;
    &:hover::before, &:active, &.active {
        transform: scaleX(1);
        transform-origin: bottom left;
    }

    &.active {
        background: $link-color-hover;
        color: $color-white;
    }

    &::before{
        transform: scaleX(0);
        transform-origin: bottom right;

        content: " ";
        display: block;
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        inset: 0 0 0 0;
        background: $link-color-hover;
        z-index: -1;
        transition: transform .3s ease;
    }
    
    &:hover {
        color: $white;
    }
}
}