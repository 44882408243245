footer[role="contentinfo"] {
    padding: 3rem;
    background: linear-gradient(90deg, $color-primary 0%, $link-color-hover 100%);
    color: $color-white;
    border-top: solid 10px $color-secondary;
    height: 100%;

    .container-footer > ul {
        display: flex;
        a {
            color: $color-white;
        }
        @media (max-width:$large) {
            flex-direction: column;
        }

        @media (min-width:$large) {
            > li {
                width: 30%;
            }
        }

        .content-menu-item {
            > span {
                font-weight: bold;
                margin-bottom: 1rem;
            }
            ul {
                padding-left: 0;
            }
            margin-bottom: 2rem;
        }
    }
}