.bloc-charte-box {
    margin-top: 50px;
    padding: 50px;
    &.two-cols {
        padding: 0;
    }
    @media (min-width:$medium) {
        padding: 20px 80px;
        max-width: 640px;
        border-top: solid 15px $color-secondary;
        background-color: $color-white;
        box-shadow: -2px 3px 8px 2px rgba(0,0,0,0.25);
        -webkit-box-shadow: -2px 3px 8px 2px rgba(0,0,0,0.25);
        -moz-box-shadow: -2px 3px 8px 2px rgba(0,0,0,0.25);
    }
    h2 {
        text-align: center;
        text-transform: uppercase;
    }
}