.address {
    .bloc-charte-box {
        @media (min-width:$medium) { 
            padding: 50px;
        }
        .bloc-infos-user {
            font-size: 1.6rem;
            color: $color-fourth;
            margin-bottom: 15px;
        }
    }
}