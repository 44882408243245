.homepage {
    main {
        padding-top: 75px;
        margin-bottom: 0;
    }
}
.content-boxes-infos {
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    justify-content: center;
    @media (min-width:$large) { 
        flex-direction: row;
        padding-top: 50px;
        margin-bottom: 50px;
    }
}
.who-are-we {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: rgb(238, 238, 238);
    .content-logo {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        .logo
        {
            width: 100px;
        }
    }
    p {
        color: $color-secondary;
    }
}
.search-box-model {
    position: relative;
    .container-column {
        display: flex;
        flex-direction: column;
        @media (min-width:$large) {
            flex-direction: row;
            height: 590px;
        }
        
        div[class*="col-"] {
            min-width: 100%;
            @media (min-width:$large) {
                min-width: 50%;
                height: 100%;
            }
        }

        > .col-left {
            background-image: url(../../img/slider-phone.png);
            background-position: top;
            background-size: cover;
            @media (min-width:$medium) {
                background-image: url(../../img/slider.png);
                background-attachment: auto;
            }
            @media (min-width:$extra-large) {
                background-size: cover;
            }
            height: 500px;
        }
        
        > .col-right {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-secondary;
            //background-image: url(../../img/search-model-background.png);
            background-position: top;
            padding: 50px 0;
            @media (min-width:$extra-large) {
                background-size: cover;
            }
        }

        @media (min-width:$large) {
            .content-col {
                display: flex;
            }
        }

        .block-search-boxModel {
            padding: 50px;
            width: 600px;
            @media (max-width:$large) {
                width: 500px;
            }
            background-image: url(../../img/transparent.png);
            box-shadow: 3px 3px 10px 3px rgba(0,0,0,0.5);
            font-size: 1.6rem;
            border-radius: 10px;
            height: 250px;
            position: relative;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (min-width:$large) {
                .col-left { 
                    min-width: 30%;
                }
                .col-right { 
                    min-width: 70%;
                }
            }

            .content-save-button {
                height: 40px;
                background-color: $color-secondary;
                color: $color-white;
                width: 100%;
                display: flex;
                justify-content: end;
                position: absolute;
                bottom: 0;
                left: 0;

                button {
                    background-color: transparent;
                    border: none;
                    font-size: 1.6rem;
                    text-transform: uppercase;
                    color: $color-white;
                    height: 100%;
                }
            }
        }
    }
}