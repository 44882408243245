.administration {
    main {
        display: flex;
    }
    aside.container-menu {
        width: 300px;
    }

    .container
    {
        width: calc(100% - 300px);
        padding: 20px;
        margin: 0;
        flex-direction: column;
    }
}