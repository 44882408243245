html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
    height: 100%;
    color: $color-tertiary;
}

@media (max-width:$large) {
    .col-6, .col-3 {
        width: 100%;
    }
}

.bloc-validation {
    display: flex;
    justify-content: end;
}