@import './_config/_variables.scss';

@import './base/_reboot.scss';
@import './base/_mixins.scss';
@import './base/_typo.scss';
@import './base/_alert.scss';

@import './components/loader.scss';
@import './components/_navbar/navbar.scss';
@import './components/_navbar/_burgerButton.scss';
@import './components/_navbar/_menuMobile.scss';
@import './components/_navbar/_menuDekstop.scss';
@import './components/_form.scss';
@import './components/_reservationStep.scss';
@import './components/_searchModule.scss';
@import './components/_button.scss';
@import './components/_caracteristicModal.scss';
@import './components/_reservationModal.scss';
@import './components/_boxModel.scss';
@import './components/_registerLoginModal.scss';
@import './components/_blocCharteBox.scss';
@import './components/_navbar/_adminMenu.scss';
@import './components/_contentBoxInfos.scss';

@import './layout/_header.scss';
@import './layout/_footer.scss';

@import './pages/_home.scss';
@import './pages/_cart.scss';
@import './pages/_register.scss';
@import './pages/_login.scss';
@import './pages/_address.scss';
@import './pages/_summury.scss';
@import './pages/_error.scss';

main {
    min-height: calc(100vh - 378px);
    padding-top: 100px;
    margin-bottom: 20px;
}