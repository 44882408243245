@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700');
@import url('https://fonts.googleapis.com/css?family=Catamaran:400,800');
.error-page {
    h1 {
        text-align: center;
    }
    .container-error {
        text-align: center;
        font-size: 180px;
        font-family: 'Catamaran', sans-serif;
        font-weight: 800;
        margin: 20px 15px;
        > span {
            display: inline-block;
            line-height: 0.7;
            position: relative;
            color: $color-secondary;
            vertical-align: middle;
            &:nth-of-type(1) {
                color: #800852;
                animation: colordancing 4s infinite;
            }
            &:nth-of-type(2) {
                width: 120px;
                height: 120px;
                border-radius: 999px;
                &:after, &::before {
                    border-radius: 0%;
                    content:"";
                    position: absolute;
                    top: 0; left: 0;
                    width: inherit; height: inherit;
                    border-radius: 999px;
                    box-shadow: inset 30px 0 0 rgba(15, 14, 88, 0.644),
                    inset 0 30px 0 rgba(8, 101, 207, 0.4),
                    inset -30px 0 0 rgba(255, 196, 140, 0.4),	
                    inset 0 -30px 0 rgba(192, 22, 70, 0.801);
                    animation: shadowsdancing 4s infinite;
                }
                &::before {
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
            &:nth-of-type(3) {
                color: $color-primary;
                animation: colordancing2 4s infinite;
            }
        }
        .screen-reader-text {
            position: absolute;
            top: -9999em;
            left: -9999em;
        }
    }

    @keyframes shadowsdancing {
        0% {
          box-shadow: inset 30px 0 0 rgba(7, 13, 70, 0.726),
                      inset 0 30px 0 rgba(32, 85, 199, 0.4),
                      inset -30px 0 0 rgba(255, 196, 140, 0.4),	
                      inset 0 -30px 0 rgba(245, 105, 145, 0.4);
        }
        25% {
          box-shadow: inset 30px 0 0 rgba(245, 105, 145, 0.4),
                      inset 0 30px 0 rgba(11, 22, 170, 0.616),
                      inset -30px 0 0 rgba(239, 250, 180, 0.4),	
                      inset 0 -30px 0 rgba(255, 196, 140, 0.4);
        }
        50% {
           box-shadow: inset 30px 0 0 rgba(255, 196, 140, 0.4),
                      inset 0 30px 0 rgba(245, 105, 145, 0.4),
                      inset -30px 0 0 rgba(16, 46, 179, 0.4),	
                      inset 0 -30px 0 rgba(41, 98, 202, 0.4);
        }
        75% {
         box-shadow: inset 30px 0 0 rgba(239, 250, 180, 0.4),
                      inset 0 30px 0 rgba(255, 196, 140, 0.4),
                      inset -30px 0 0 rgba(245, 105, 145, 0.4),	
                      inset 0 -30px 0 rgba(209, 242, 165, 0.4);
        }
        100% {
          box-shadow: inset 30px 0 0 rgba(6, 21, 73, 0.4),
                      inset 0 30px 0 rgba(9, 20, 56, 0.849),
                      inset -30px 0 0 rgba(255, 196, 140, 0.4),	
                      inset 0 -30px 0 rgba(245, 105, 145, 0.4);
        }
      }
      @keyframes colordancing {
        0% {
          color: #D1F2A5;
        }
        25% {
          color: #F56991;
        }
        50% {
          color: #FFC48C;
        }
        75% {
          color: #36a4e4;
        }
        100% {
          color: #0e2794;
        }
      }
      @keyframes colordancing2 {
        0% {
          color: #FFC48C;
        }
        25% {
          color: #1cadb3;
        }
        50% {
          color: #2649aa;
        }
        75% {
          color: #be1444;
        }
        100% {
          color: #FFC48C;
        }
    }

}