section[role="reservationStep"] {
    min-height: 30px;
    position: fixed;
    top: 75px;
    z-index: 1000;
    margin-bottom: 20px;
    width: 100%;

    ~ main {
        padding-top: 160px;
    }
    nav[role="navigation"] {
        background-color: $color-white;
    }
    nav[role="navigation"] ul {
        display: flex;
        padding-left: 0;
        margin-left: 0;
        
        li {
            display: none;
            height: 56px;

            &.active {
                display: flex;
                align-items: center;
                @media (max-width:$large) {
                    justify-content: center;
                }
                width: 100%;
                padding: 2rem 1rem;
                text-transform: uppercase;
                font-size: 2rem;
                border-bottom: solid 1px $color-tertiary;
            }
        }

        @media (min-width:$large) {
            li {
                width: 250px;
                -webkit-box-shadow: 5px 4px 9px -3px #dedede; 
                box-shadow: 5px 4px 9px -3px #dedede;
                align-items: flex-end;
                display: flex;
                justify-content: end;
                font-size: 1.2rem;
                background-color: $color-white;
                position: relative;
                text-transform: uppercase;
                padding: 2rem 1rem;
                
                &.recap-and-sign {
                    text-align: end;
                    align-items: center;
                }

                &.active {
                    width: 250px;
                    border-bottom: none;
                    color: $color-primary;
                    font-size: 1.2rem;
                }
                &.passed, &.passed a {
                    width: 250px;
                    border-bottom: none;
                    color: $color-fourth;
                    font-size: 1.2rem;
                }

                .arrow {
                    position: absolute;
                    top: 0;
                    right: -50px;
                    width: 50px;
                    height: 100%;
                    z-index: 10;
                    overflow: hidden;

                    &::after {
                        content: "";
                        position: absolute;
                        width: 42px;
                        height: 42px;
                        background: #fff;
                        transform: rotate(45deg);
                        top: 8px;
                        left: -21px;
                        box-shadow: 3px -4px 5px -2px rgb(0 0 0 / 50%);
                    }
                }
            }
        }
    }
}