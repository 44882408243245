.container-burger {
  @media (min-width:$large) {
    display: none;
  }

  position: relative;
  &::after {
    content: "MENU";
    position: absolute;
    top: 11px;
    right: -54px;
    color: $color-primary;
  }
  &.close {
      border: 3px solid $color-primary;
      height: 50px;
      width: 50px;
      
      .btn--hamburger, .btn--hamburger::before, .btn--hamburger::after {
        background-color: $color-primary;
        content: "";
        display: block;
        height: 3px;
        width: 20px;
        transition: all .5s ease;
      }
      
      .btn--hamburger {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        
        &::before {
          margin-top: -8px;
          position: absolute;
        }
        
        &::after {
          margin-top: 8px;
          position: absolute;
        }
      }
  }

  &.open {
      .btn--hamburger {
          background-color: transparent;
          left: 54%;
          
          &::before, &::after {
          transform: rotate(45deg);
          transform-origin: 0 50%;
          width: 22px;
          }
          
          &::after {
          transform: rotate(-45deg);
          }
      }
  }
}
  