.login {
    .bloc-charte-box {
        @media (min-width:$medium) {
            display: flex;
        }
        .bloc-login {
            margin-bottom: 20px;
            border-bottom: solid 1px $color-tertiary;
            padding-bottom: 20px;
            @media (min-width:$medium) {
                padding: 30px;
                margin-bottom: 0;
                border-bottom: none;
                border-right: solid 1px $color-tertiary;
                width: 50%;
            }
        }
        .bloc-register {
            text-align: center;
            @media (min-width:$medium) {
                padding: 30px;
                width: 50%;
            }
        }
    }
}