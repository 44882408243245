.mask-loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background:url('../../img/transparent.png');
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .loading{
        display:flex;justify-content:center;
        align-items:center;
        flex-flow:row wrap;
        height:115px;
        width:120px;
        transform:rotate(45deg);
      }
      
      .loaders{
        display:flex;
        justify-content:center;
        align-items:center;
        flex-flow:row wrap;
        height:30px;
        width:30px;
        border:1px solid $color-tertiary;
        margin:15px;
        -webkit-animation:loader 2s linear infinite;
      }
      
      .loaders:nth-child(2), .loaders:nth-child(3){
        background-color: $color-primary;
        opacity:0.9;
      }
      
      .loaders:nth-child(1)
      {
        background-color: $color-secondary;
        opacity:0.9;
      }
        
      @-webkit-keyframes loader{
        0% {
              transform: scale(1.5);
          }
          25% {
              transform: scale(1.55);
          }
          50% {
              transform: scale(1.1);
          }
          75% {
              transform: scale(1.2);
              transform:rotate(180deg);
              background-color: $color-tertiary;
          }
          100% {
              transform: scale(1.5);
          }
      }
}