.register {
    @media (min-width:$large) { 
        .container {
            width: 1000px;
            max-width: 1000px;
        }
    }
    .bloc-charte-box {
        padding: 50px;
        @media (min-width:$medium) { 
            .bloc-form-register {
                .col-12:first-child {
                    border-right: solid 1px $color-tertiary;
                }
            }
        }
    }
    .btn {
        max-width: 100px;
    }
}