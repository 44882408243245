$tiny: 480px !default;
$small: 576px !default;
$medium: 768px !default;
$large: 992px !default;
$extra-large: 1200px !default;
$super-large: 1920px !default;

$font-size-base: 1.6rem !default;

$font-sizes: (
  base: (
    mobile: 1.4rem,
    desktop: $font-size-base,
  ),
  strong: (
    mobile: 1.6rem,
    desktop: 2.5rem,
  ),
  h1: (
    mobile: 2.6rem,
    desktop: 2.6rem,
  ),
  h2: (
    mobile: 2.4rem,
    desktop: 3rem,
  ),
  h3: (
    mobile: 2rem,
    desktop: 2.3rem,
  ),
  h4: (
    mobile: 1.8rem,
    desktop: 2rem,
  ),
  h5: (
    mobile: 1.6rem,
    desktop: 1.8rem,
  ),
  h6: (
    mobile: 1.4rem,
    desktop: 1.6rem,
  ),
) !default;

$line-height-s: 1.3 !default;
$line-height-base: 1.5 !default;
$line-height-l: 1.7 !default;

$margin-bottom-base: 2rem !default;
$headings-margin-bottom: $margin-bottom-base !default;
$paragraph-margin-bottom: $margin-bottom-base !default;

$weight-light: 300 !default;
$weight-regular: 400 !default;
$weight-medium: 500 !default;
$weight-semi-bold: 600 !default;
$weight-bold: 700 !default;
$weight-extra-bold: 800 !default;
$weight-black: 900 !default;

$color-pink: #D60B52;
$color-blue: #0D0C23;
$orchid-blue: #0828cc;
$electric-blue: #1e3fe6;
$caeruleum-blue: #00bafd;
$denim-blue: #334ccc;
$night-blue: #0e2073;
$lavande: #a8aeff;
$light-sky-blue: #e8f0fe;
$light-night: #c2ccde;
$light-sky-blue-02: #f3f5f9;
$sky-gray: #c1cad5;
$gray-200: #c6c6c6;
$gray-400: #a2a2a2;
$gray-500: #8e8c8c;
$gray-600: #707070;

$black: #000;
$disabled-content: $gray-500;
$disabled-background: #e7e9ed;
$green-400: #6ccda3;
$gray-100: #eee;
$gray-800: #424242;
$gray-900: #212121;
$orange-1000: #ffc107;
$red-700: #ff3b00;
$white: #fff;
$blue-bayoux: #4a6e78;
$grey: #6D6D6D;
$blue-box: #144391;

$color-danger: $red-700;
$color-info: $color-blue;
$color-ghost: transparent;
$color-success: $green-400;
$color-warning: $orange-1000;
$color-white: $white;
$color-black: $black;
$color-disabled-background: $disabled-background;
$color-disabled-content: $disabled-content;

$color-primary: $color-pink;
$color-secondary: $color-blue;
$color-tertiary: $grey;
$color-fourth: $blue-box;

$link-color: $color-pink;
$link-color-hover: darken($link-color, 15%);
$link-decoration: none;
$link-decoration-hover: none;

$btn-list: (
  (primary, $color-primary, $color-white, $color-primary, no, $color-secondary, $color-secondary, 0, $color-white),
  (secondary, $color-secondary, $color-white, $color-secondary, no, $color-primary, $color-primary, 15%, $color-white),
  (
    tertiary,
    $color-secondary,
    $color-white,
    $color-secondary,
    darken,
    $color-secondary,
    $color-secondary,
    10%,
    $color-white
  ),
  (success, $color-success, $color-white, $color-ghost, darken, $color-success, $color-success, 10%, $color-white),
  (danger, $color-danger, $color-white, $color-ghost, darken, $color-danger, $color-danger, 10%, $color-white),
  (warning, $color-warning, $color-white, $color-ghost, darken, $color-warning, $color-warning, 10%, $color-white),
  (info, $color-ghost, $color-info, $color-info, no, $color-info, $color-info, 5%, $color-white),
  (light, $color-ghost, $color-secondary, $color-secondary, no, $color-secondary, $color-secondary, 0, $color-white),
  (link, $color-ghost, $color-primary, $color-primary, no, $color-primary, $color-primary, 0, $color-white),
  (
    is-disabled,
    $color-disabled-background,
    $color-disabled-content,
    $color-disabled-background,
    no,
    $color-disabled-background,
    $color-disabled-background,
    0,
    $color-disabled-content
  ),
  (white, $color-ghost, $color-white, $color-white, no, $color-white, $color-white, 0, $color-secondary),
  (ghost, $color-ghost, $color-secondary, $color-secondary, no, $color-secondary, $color-secondary, 0, $color-white),
  (ghost-white, $color-ghost, $color-white, $color-white, no, $color-white, $color-white, 0, $color-primary)
) !default;

$color-list: (
  (primary, $color-primary),
  (secondary, $color-secondary),
  (success, $color-success),
  (warning, $color-warning),
  (danger, $color-danger),
  (info, $color-info),
  (dark, $color-black),
  (white, $color-white)
) !default;

$checkbox-size: 17px;
$checkbox-animation-ripple: 700ms;
$checkbox-animation-check: 0.3s;
$lightbg-text: rgba(0, 0, 0, 0.84);
$checked-colour: #2faef8;

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-prefers-reduced-motion-media-query: true !default;
$enable-hover-media-query: false !default; 
$enable-grid-classes: true !default;
$enable-pointer-cursor-for-buttons: true !default;
$enable-print-styles: true !default;
$enable-responsive-font-sizes: false !default;
$enable-validation-icons: true !default;
$enable-deprecation-messages: true !default;

$card-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.16);


$color-bkg-reductionLarge: $color-white;
$color-reductionLarge: $color-primary;

$color-bkg-footer: linear-gradient(90deg, rgba(2, 3, 65, 1) 0%, rgba(6, 19, 128, 1) 100%);