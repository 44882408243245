.content-module-search {
    border: solid 1px $color-tertiary;
    border-radius: 7px;
    padding: 15px;
    max-width: 100%;
    margin-bottom: 20px;
    @media (min-width:$large) {
        max-width: 400px;
    }

    form {
        .select2.select2-container--default {
            span.select2-selection {
                border: 2px solid $color-tertiary;
            }
        }
    }

    .content-critary {
        #params {
            a {
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }

    .block-search-boxModel {
        .content-col {
            margin-bottom: 20px;
        }
        .content-save-button {
            div {
                display: flex;
                align-items: flex-end;
                justify-content: end;
            }
        }
        .col-left {
            display: none;
        }
    }
}