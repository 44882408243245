form {
    .select2.select2-container--default {
        &.select2-results__option--highlighted.select2-results__option--selectable {
            background-color: $color-secondary;
        }
      
        span.select2-selection {
            height: 50px;
            background-color: transparent;
            border: 3px solid $color-tertiary;
            border-radius: 13px;
        }

        .select2-selection__rendered {
            height: 100%;
            display: flex;
            align-items: center;
        }

        .select2-selection__arrow {
            height: 100%;
            display: flex;
            align-items: center;
            width: 30px;
            b {
                transform: rotate(45deg) translateY(-50%);
                width: 11px;
                height: 11px;
                transition: all .4s ease-in-out;
                box-sizing: border-box;
                border-style: solid;
                border-color: $color-primary;
                border-width: 0 3px 3px 0;
                transform-origin: 50% 0;
            }
        }

        &.select2-container--open {
            .select2-selection__arrow b {
                transform: rotate(-135deg) translateY(-50%);
                border-style: solid;
                border-color: $color-primary;
                border-width: 0 3px 3px 0;
            }
        }
    }
    textarea {
        width: 100%;
        border: solid 1px $color-secondary;
        border-radius: 7px;
    }
    .form-group {
        margin-bottom: 20px;
    }
    .input-group input, input {
        border: solid 1px $color-secondary;
        border-radius: 7px;
        height: 4.5rem;
        width: 100%;
        padding: 1rem;
        font-size: 1.4rem;
    }
    .block-button {
        display: flex;
        align-items: flex-end;
        justify-content: end;
    }

    #registration_form_gender, #user_form_gender {
        display: flex;
        .block-radio {
            width: 100px;
            display: flex;
            align-items: center;
            input {
                width: 20px;
            }
            ~ label[class="required"] {
                display: none;
            }
        }
    }
    .block-agree-terms {
        display: flex;
        align-items: center;
        input {
            width: 20px;
            margin-right: 10px;
        }
    }
    .password-strength ul{
        font-size: 1.4rem;
        padding: 0;
    }
}