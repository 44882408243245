.cart {
    .btn--delete, .btn--remove {
        margin-right: 5px;
    }

    .block-box-model {
        @media (max-width:$large) {
            width: 100%;
        }
        width: 450px;
        .content-price {
            border-bottom: none;
            border-right: solid 1px $color-white;
        }
        .content-volume {
            width: 25%;
        }
    }
    .content-button {
        display: flex;
        justify-content: end;
    }
}