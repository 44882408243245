#resultSearchBoxModel {
    .box-model [class^='col-']{
        margin-bottom: 20px;
    }
}
.block-box-model {
    width: 100%;
    margin-bottom: 20px;
    @media (min-width:$large) {
        flex-direction: row;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 0;
        padding-right: 0;
    }
    .container-box-model {
        display: flex;
        align-items: center;
        background-color: $color-fourth;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color: $color-white;
        padding: 1rem;
        margin-bottom: 10px;
        .ico-box-model {
            background-image: url('../../img/ico-box-color.svg');
            height: 80px;
            width: 80px;
            background-position-y: 20px;
            background-position-x: 15px;
            background-color: $color-white;
            background-repeat: no-repeat;
            border-radius: 7px;
            margin-bottom: 0;
            margin-right: 15px;
        }
        .content-volume {
            font-size: 2rem;
            font-weight: bold;
            padding-bottom: 15px;
            width: 40%;
            border-bottom: none;
            border-right: solid 1px $color-white;
        }
        .content-price {
            font-size: 3rem;
            font-weight: bold;
            width: 30%;
            display: flex;
            flex-direction: column;
            text-align: center;
            .price-without-tva {
                font-size: 1.6rem;
            }
        }
        .content-quantity {
            font-size: 1.6rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 15px;
            width: 25%;
            .quantity {
                font-size: 5rem;
            }
        }
    } 
}
.content-button {
    display: flex;
    justify-content: end;
}
