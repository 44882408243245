.summury {
    .container {
        @media (min-width:$medium) {
            width: 540px;
        }
        .price-total {
            text-align: right;
            font-weight: bold;
            span {
                font-size: 3rem;
                color: $color-primary;
            }
        }
    }
}