.content-menu-dekstop {
    display: none;
    @media (min-width:$large) {
        display: block;
        ul {
            padding: 0;
            list-style-type: none;
            margin-bottom: 0;
            display: inline-flex;

            li {
                padding: 10px;
            }
        }
    }
}