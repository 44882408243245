#characteristicsModal {
    .modal-dialog {
        top: 20%;

        h2 {
            font-size: 1.6rem;
            text-transform: uppercase;
            color: $color-secondary;
        }
        
        h3 {
            font-size: 1.2rem;
            color: $color-tertiary;
            .description {
                font-size: 1.4rem;
            }
        }

        ul {
            padding: 0;
        }

        .description {
            font-weight: bold;
        }

        .img-caracteristic {
            display: block;
            height: 100px;
            background-image: url('../../img/caracteristiques-box.png');
            background-repeat: no-repeat;
        }

        .price-without-tva {
            font-size: 2rem;
            color: $color-primary;
            .more-info {
                font-size: 2rem;
                color: $color-tertiary;
            }
        }
        .price-with-tva {
            font-size: 4rem;
            color: $color-primary;
            font-weight: bold;

            .more-info {
                font-size: 2rem;
                color: $color-tertiary;
            }
        }

        .modal-header {
            border-bottom: 0;
        }

        .modal-content {
            padding: 20px;
        }
        button {
            margin-bottom: 20px;
        }
    }
    @media (min-width:$large) { 
        .modal-dialog {
            min-width: 700px;

            .modal-body {
                .col:first-child {
                    border-right: 1px solid $color-tertiary;
                }
            }
        }
    }
}